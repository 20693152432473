<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.data.status)">
        <span>{{ organizationTypeString(params.data.status) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererStatus',
    computed: {
        chipColor() {
            return (value) => {
                switch (value) {
                    case 0:
                        return "";
                    case 1:
                        return "success";
                    case 2:
                        return "danger";
                    case 3:
                        return "warning";
                    case 4:
                        return "success";
                    case 5:
                        return "danger";
                    case 6:
                        return ""
                    default:
                        return ""
                }
            }
        },
        organizationTypeString() {
            return (value) => {
                switch (value) {
                    case 0:
                        return this.$t('statusActive.waiting');
                    case 1:
                        return this.$t('statusActive.agree');
                    case 2:
                        return this.$t('statusActive.decline');
                    case 3:
                        return this.$t('statusActive.cancel');
                    case 4:
                        return this.$t('statusActive.waitingExpire');
                    case 5:
                        return this.$t('statusActive.abort');
                    case 6:
                        return this.$t('statusActive.expries');
                    case 7:
                        return this.$t('statusActive.failure');
                }
            }
        }
    }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success), .15);
        color: rgba(var(--vs-success), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-warning {
        background: rgba(var(--vs-warning), .15);
        color: rgba(var(--vs-warning), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-danger {
        background: rgba(var(--vs-danger), .15);
        color: rgba(var(--vs-danger), 1) !important;
        font-weight: 500;
    }
}
</style>
