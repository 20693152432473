<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-col md:w-full" v-if="this.params.data.status == 0">
            <vx-tooltip class="f-r" :text="$t('statusActive.approve')" position="top">
                <feather-icon icon="CheckIcon" class="icon-action"
                              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                              @click="confirmAccept"/>
            </vx-tooltip>

            <vx-tooltip class="f-r" :text="$t('statusActive.decline')" position="top">
                <feather-icon icon="XIcon" class="icon-action1"
                              svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
                              @click="confirmReject"/>
            </vx-tooltip>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CellRendererActions',
    data() {
        return {
        }
    },
    methods: {
        confirmAccept() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: this.$t('root.requestConsent'),
                text: `${this.$t('root.requestConsent')} "${this.params.data.cameraPackageResponseDTO.packageName}" ?`,
                accept: this.accept,
                acceptText: this.$t('button.agree'),
                cancelText: this.$t('button.cancel')
            })
        },
        accept(){
            this.acceptRecord();
        },

        acceptRecord() {
            this.$vs.loading();
            let url = `/camera-package-history/accept?id=${this.params.data.id}&organizationId=${this.params.data.organizationId}`;
            this.$crm.put(url).then(() => {
                this.$vs.loading.close();
                return this.showAcceptSuccess(this.$t('root.fewMinutes'));
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showAcceptSuccess(text) {
            this.$store.commit('UPDATE_REQUEST_PACKAGE', true);
            this.$vs.notify({
                color: 'success',
                text: text,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },

        confirmReject() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('root.cancellation'),
                text: `${this.$t('root.request')} "${this.params.data.cameraPackageResponseDTO.packageName}" ?`,
                accept: this.rejectRecord,
                acceptText: this.$t('button.delete'),
                cancelText: this.$t('button.cancel')
            })
        },
        rejectRecord() {
            this.$vs.loading();
            this.$crm.put(`/camera-package-history/reject?id=${this.params.data.id}&organizationId=${this.params.data.organizationId}`).then(() => {
                this.$vs.loading.close();
                return this.showAcceptSuccess(this.$t('root.refused'));
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    }
}
</script>
<style scoped>

.icon-action {
    color: rgba(var(--vs-primary),1);
}

.icon-action:hover {
    color: rgba(var(--vs-primary),1);
    opacity: 0.5;
}

.icon-action1 {
    color: red;
}

.icon-action1:hover {
    color: red;
    opacity: 0.5;
}

</style>

