<template>
    <div id="hardware_device">
        <div class="vx-card p-6">
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/2 w-full">
                    <TitleScreen></TitleScreen>
                </div>
            </div>
            <div class="vx-row items-center mt-4">
                <div class="vx-col md:w-1/4 sm:w-auto">
                    <label class="vs-input--label">{{ $t('menuOrganization.branch') }}</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                        <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-3/4 w-full">
                    <vs-input class="float-right"
                              v-model="searchQuery"
                              @input="updateSearchQuery" :placeholder="$t('button.search')"/>
                </div>
            </div>
            <ag-grid-vue ref="agGridTable"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="devicesData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>
            <div class="vx-row w-full">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</template>

<script>

import TitleScreen from "../../../layouts/main/TitleScreen"
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus"

export default {
    data() {
        return {
            searchQuery: "",
            devicesData: [],
            maxPageNumbers: 7,
            gridOptions: {},
            gridApi: null,
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuOrganization.deviceId'),
                    field: 'deviceId',
                    width: 150
                },
                {
                    headerName: this.$t('menuOrganization.deviceSeries'),
                    field: 'snDevice',
                    width: 200
                },
                {
                    headerName: this.$t('menuOrganization.deviceName'),
                    field: 'deviceName',
                    width: 200
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
        }
    },

    components: {
        TitleScreen,
        AgGridVue,
        CellRendererActions,
        CellRendererStatus,
        Pagination,
        vSelect
    },

    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateHardwareDevice'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_HARDWARE_DEVICE', false);
            }
        }
    },

    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj ? obj.value : null;
                this.getHardwareDevice();
            }
        }
    },

    mounted() {
        this.gridApi = this.gridOptions.api;
    },

    created() {
        this.initScreen()
    },

    methods: {

        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },

        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },

        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuOrganization.null');
        },

        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: this.$t('menuOrganization.noBranch'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            this.organizationBranchOptions.unshift({value: null, label: this.$t('menuOrganization.all')});
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

            this.getHardwareDevice();
        },
        getHardwareDevice(){
            this.$vs.loading()
            let data = {
                organizationBranchId: this.selectedOrganizationBranchValue
            }
            this.$crm.post('device/hardware/find-by-condition', data).then(res => {
                this.devicesData = res.data;
                this.$vs.loading.close()
            }).catch(err => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        }
    }

}
</script>
